.sc-popup-window {
  position: relative;
  width: 150px;
}

.sc-popup-window--cointainer {
  position: absolute;
  bottom: 20px;
  right: 100px;
  width: 330px;
  max-height: 260px;
  height: 260px;
  box-shadow: 0px 7px 40px 2px rgba(148, 149, 150, 0.3);
  background: white;
  border-radius: 10px;
  outline: none;
  transition: 0.2s ease-in-out;
  z-index: 1;
  padding: 0px 5px 5px 5px;
  box-sizing: border-box;
}

.sc-popup-window--cointainer.closed {
  opacity: 0;
  visibility: hidden;
  bottom: 14px;
}

.sc-popup-window--cointainer:after {
  content: "";
  width: 14px;
  height: 14px;
  background: white;
  position: absolute;
  z-index: -1;
  bottom: -6px;
  right: 28px;
  transform: rotate(45deg);
  border-radius: 2px;
}

.sc-popup-window--search {
  width: 290px;
  box-sizing: border-box;
  margin: auto;
  display: block;
  border-width: 0px 0px 1px 0px;
  color: #565867;
  padding-left: 25px;
  height: 40px;
  font-size: 14px;
  background-image: url(https://js.intercomcdn.com/images/search@2x.32fca88e.png);
  background-size: 16px 16px;
  background-repeat: no-repeat;
  background-position: 0 12px;
  outline: none;
}

.sc-popup-window--search::placeholder {
  color: #C1C7CD;
}